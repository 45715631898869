import React, { useRef, useState } from 'react';
import { useInView } from '../hooks/useInView';
import toast from 'react-hot-toast';

const DemoForm = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef);
  const [formData, setFormData] = useState({
    'full-name': '',
    'email-address': '',
    'phone': '',
    'company-name': '',
    'monthly-calls': ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://hook.eu2.make.com/lgqxq6ugp51qoxg5f5ktag8q5jybx9sq', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Submission failed');
      }

      toast.success('Thank you! We\'ll be in touch soon.');
      setFormData({
        'full-name': '',
        'email-address': '',
        'phone': '',
        'company-name': '',
        'monthly-calls': ''
      });
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('Form submission error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div id="demo-section" className={`demo-section fade-in-section ${isInView ? 'is-visible' : ''}`} ref={sectionRef}>
      <div className="demo-header">
        <span className="gradient-text"> Book a Demo</span>
        <p>Start processing more calls and closing more leads with Doora in no time...</p>
      </div>
      
      <form className="demo-form" onSubmit={handleSubmit}>
        <div className="form-field">
          <label>Full Name</label>
          <input 
            type="text" 
            name="full-name"
            value={formData['full-name']}
            onChange={handleChange}
            placeholder="Jane Doe" 
            required
          />
        </div>
        
        <div className="form-row">
          <div className="form-field">
            <label>Email Address</label>
            <input 
              type="email" 
              name="email-address"
              value={formData['email-address']}
              onChange={handleChange}
              placeholder="jane.doe@mail.com" 
              required
            />
          </div>
          
          <div className="form-field">
            <label>Phone (Optional)</label>
            <input 
              type="tel" 
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="+44 77 1234 5678" 
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-field">
            <label>Your Company Name</label>
            <input 
              type="text" 
              name="company-name"
              value={formData['company-name']}
              onChange={handleChange}
              placeholder="Company name" 
              required
            />
          </div>
          
          <div className="form-field">
            <label>How many calls do you receive per month? (Estimate)</label>
            <input 
              type="text" 
              name="monthly-calls"
              value={formData['monthly-calls']}
              onChange={handleChange}
              placeholder="Number of calls" 
              required
            />
          </div>
        </div>

        <button 
          type="submit" 
          style={{"margin-left": "33rem"}} 
          className="demo-hero-button"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Book a demo'}
        </button>
      </form>
    </div>
  );
};

export default DemoForm; 