import React, { useRef } from 'react';
import { useInView } from '../hooks/useInView';

const Features = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef);

  const features = [
    {
      title: "Easy to Understand Conversations",
      description: "Your customers can communicate naturally, just as they would with a human agent—responding to questions, changing topics, and expressing themselves freely.",
      image: "/EasyToUnderstandConversationsCard.png"
    },
    // {
    //   title: "Handles over 80% of inbound Calls",
    //   description: "Your virtual agent autonomously handles a significant number of customer calls, only passing on qualified ones to human agents for further assistance.",
    //   image: "/DALL-E-PLACEHOLDER.png"
    // },
    {
      title: "Complete Customisability",
      description: "From the name, to the voice, to the property database and personal knowledge of your business, Doora is fully tailorable to your specific business.",
      image: "/CompleteCustomisationCard.png"
    },
    {
      title: "Full System Integration",
      description: "Our team offers full onboarding, integrating Doora into your day-to-day systems seamlessly, making it easier than ever to get started.",
      image: "/SystemsIntegration.png"
    },
    {
      title: "Easy to Action and Respond",
      description: "Doora simply and clearly organises all processed tasks for review, further action, or response through the Dashboard.",
      image: "/ActionandRespond.png"
    }
  ];

  return (
    <section className={`features fade-in-section ${isInView ? 'is-visible' : ''}`} ref={sectionRef}>
      <h2>Why Choose Doora?</h2>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-image">
              <img src={feature.image} alt={feature.title} />
            </div>
            <div className="feature-content">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features; 