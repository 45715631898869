import React, { useState, useRef } from 'react';
import { useInView } from '../hooks/useInView';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef);

  const faqs = [
    {
      question: "What is an AI voice agent?",
      answer: "An AI Voice Agent is a conversational voice agent that, using artificial intelligence, can autonomously and naturally understand and respond to customer inquiries during phone calls.\n\nVoice agents use two main technologies: voice recognition and speech processing. The agent uses voice recognition to understand what the user is saying, transforms it into text that it can read, deals with the request, and then converts the text back into speech to talk to the person on the call."
    },
    {
      question: "Why integrate an AI voice agent into your customer service?",
      answer: "AI Voice Agents provide 24/7 customer support, handle multiple calls simultaneously, and consistently deliver high-quality service. They reduce wait times, lower operational costs, and free up human agents to handle more complex cases."
    },
    {
      question: "How to integrate a voice agent into your business?",
      answer: "Integration is straightforward and involves connecting the voice agent to your existing systems. Our team handles the technical setup, training, and customisation to match your business needs. The process usually takes just a few weeks."
    },
    {
      question: "How much does a voice agent cost?",
      answer: "Pricing varies based on call volume and specific requirements. We offer flexible plans that scale with your business needs. Contact us for a customised quote that fits your budget and objectives."
    }
  ];

  return (
    <section id="faq-section" className={`faq fade-in-section ${isInView ? 'is-visible' : ''}`} ref={sectionRef}>
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <button 
              className={`faq-question ${activeIndex === index ? 'active' : ''}`}
              onClick={() => setActiveIndex(activeIndex === index ? null : index)}
            >
              {faq.question}
              <span className="faq-icon">{activeIndex === index ? '−' : '+'}</span>
            </button>
            {activeIndex === index && (
              <div className="faq-answer">
                {faq.answer.split('\n\n').map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ; 